<!--
 * @Author: your name
 * @Date: 2020-10-20 14:14:00
 * @LastEditTime: 2020-10-21 09:47:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \GrandHyattc:\Users\25740\Desktop\jciweb\src\views\AuthPages\RecoverPassword.vue
-->
<template>
  <div class="reset2fa">
    <h4 class="mb-3 mt-5">Reset payment password</h4>
    <p class="mt-4 mb-1">Please enter the payment password:</p>
    <div class="wrap">
      <input
        class="pwd"
        v-model="pass1_1"
        @input="onload"
        type="password"
        maxlength="1"
        oninput="value=value.replace(/[^\d]/g,'')"
      />
      <input
        class="pwd"
        type="password"
        v-model="pass1_2"
        maxlength="1"
        oninput="value=value.replace(/[^\d]/g,'')"
      />
      <input
        class="pwd"
        type="password"
        v-model="pass1_3"
        maxlength="1"
        oninput="value=value.replace(/[^\d]/g,'')"
      />
      <input
        class="pwd"
        type="password"
        v-model="pass1_4"
        maxlength="1"
        oninput="value=value.replace(/[^\d]/g,'')"
      />
      <input
        class="pwd"
        type="password"
        v-model="pass1_5"
        maxlength="1"
        oninput="value=value.replace(/[^\d]/g,'')"
      />
      <input
        class="pwd"
        type="password"
        v-model="pass1_6"
        maxlength="1"
        oninput="value=value.replace(/[^\d]/g,'')"
      />
    </div>
    <p class="mt-4 mb-1">Please enter the confirmation again:</p>
    <div class="twice">
      <input
        class="pwd"
        v-model="pass2_1"
        @input="ontwice"
        type="password"
        maxlength="1"
        oninput="value=value.replace(/[^\d]/g,'')"
      />
      <input
        class="pwd"
        v-model="pass2_2"
        type="password"
        maxlength="1"
        oninput="value=value.replace(/[^\d]/g,'')"
      />
      <input
        class="pwd"
        v-model="pass2_3"
        type="password"
        maxlength="1"
        oninput="value=value.replace(/[^\d]/g,'')"
      />
      <input
        class="pwd"
        v-model="pass2_4"
        type="password"
        maxlength="1"
        oninput="value=value.replace(/[^\d]/g,'')"
      />
      <input
        class="pwd"
        v-model="pass2_5"
        type="password"
        maxlength="1"
        oninput="value=value.replace(/[^\d]/g,'')"
      />
      <input
        class="pwd"
        v-model="pass2_6"
        type="password"
        maxlength="1"
        oninput="value=value.replace(/[^\d]/g,'')"
      />
    </div>
    <div>
      <b-button
        variant="primary"
        type="submit"
        style="float: right"
        class="mt-5 mr-2"
        @click="editPassword"
        >Submit</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Resetpayword",
  data: () => ({
    pass1_1: "",
    pass1_2: "",
    pass1_3: "",
    pass1_4: "",
    pass1_5: "",
    pass1_6: "",
    pass2_1: "",
    pass2_2: "",
    pass2_3: "",
    pass2_4: "",
    pass2_5: "",
    pass2_6: "",
    user_token: "",
    email: "",
  }),
  mounted() {},
  methods: {
    editPassword() {
      let params = window.location.href.split("token=")[1];
      this.user_token = params.split("&email=")[0];
      this.email = params.split("&email=")[1];
      if (
        this.pass1_1 !== "" &&
        this.pass1_2 !== "" &&
        this.pass1_3 !== "" &&
        this.pass1_4 !== "" &&
        this.pass1_5 !== "" &&
        this.pass1_6 !== "" &&
        this.pass1_1 == this.pass2_1 &&
        this.pass1_2 == this.pass2_2 &&
        this.pass1_3 == this.pass2_3 &&
        this.pass1_4 == this.pass2_4 &&
        this.pass1_5 == this.pass2_5 &&
        this.pass1_6 == this.pass2_6
      ) {
        let word = `${this.pass1_1}${this.pass1_2}${this.pass1_3}${this.pass1_4}${this.pass1_5}${this.pass1_6}`;
        this.$http
          .resetPaymentPassword({
            user_token: this.user_token,
            password: word,
            email: this.email,
          })
          .then((res) => {
            if (res.status == 200) {
              localStorage.clear();
              this.$router.push("/auth/sign-in1");
            }
          });
      } else {
        this.$message.info(
          "Please confirm that the payment passwords entered twice are consistent"
        );
      }
    },
    onload() {
      let transactionCodeInput = document.getElementsByClassName("wrap")[0];
      transactionCodeInput.onkeydown = function (e) {
        let target = e.srcElement || e.target;
        let keyCode = e.keyCode;
        let maxLength = parseInt(target.attributes["maxlength"].value, 10);
        let myLength = target.value.length;
        if (myLength >= maxLength) {
          let next = target;
          while ((next = next.nextElementSibling)) {
            if (next == null) break;
            if (next.tagName.toLowerCase() === "input") {
              if (keyCode == 8) {
                break;
              } else {
                next.focus();
                break;
              }
            }
          }
        } else if (myLength === 0) {
          // Move to previous field if empty (user pressed backspace)
          let previous = target;
          while ((previous = previous.previousElementSibling)) {
            if (previous == null) break;
            if (previous.tagName.toLowerCase() === "input") {
              if (keyCode == 8) {
                previous.focus();
                break;
              } else {
                break;
              }
            }
          }
        }
      };
    },
    ontwice() {
      let transactionCodeInput = document.getElementsByClassName("twice")[0];
      transactionCodeInput.onkeydown = function (e) {
        let target = e.srcElement || e.target;
        let keyCode = e.keyCode;
        let maxLength = parseInt(target.attributes["maxlength"].value, 10);
        let myLength = target.value.length;
        if (myLength >= maxLength) {
          let next = target;
          while ((next = next.nextElementSibling)) {
            if (next == null) break;
            if (next.tagName.toLowerCase() === "input") {
              if (keyCode == 8) {
                break;
              } else {
                next.focus();
                break;
              }
            }
          }
        } else if (myLength === 0) {
          // Move to previous field if empty (user pressed backspace)
          let previous = target;
          while ((previous = previous.previousElementSibling)) {
            if (previous == null) break;
            if (previous.tagName.toLowerCase() === "input") {
              if (keyCode == 8) {
                previous.focus();
                break;
              } else {
                break;
              }
            }
          }
        }
      };
    },
  },
};
</script>


<style lang="scss" scoped>
.pwd {
  width: 45px;
  height: 45px;
  margin: 10px;
  opacity: 1;
  border: 1px solid #f1f1f1;
  border-radius: 11px;
  text-align: center;
  vertical-align: middel;
}
</style>
